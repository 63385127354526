import React from "react"
import Layout from "../components/layout"
import {GatsbySeo} from "gatsby-plugin-next-seo/src/meta/gatsby-seo";
import HeaderH2 from "../components/headerH2";
import styled from "@emotion/styled";

const LiCheck = styled.li`
&:before {
  content: '✅';
  margin-right:1rem;
}
`
const LiCross = styled.li`
&:before {
  content: '❌';
  margin-right:1rem;
}
`

export default function Flutter() {
  return (
    <>
      <GatsbySeo title="Flutter in_app_purchase Plugin or LinkFive Flutter Plugin"
                 description="Sometimes it is difficult to choose between different plugins. Here is a short overview of the advantages of the LinkFive Flutter plugin compared to the in_app_purchase plugin."
      />
      <Layout utmSource="flutter" fullWave={false} bottomWave={false} callToAction={false}>
        <div className="pt-24 pb-12 bg-l6-background">
          <div
            className="container md:max-w-6xl px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center lg:mb-12 lg:mt-12">
            <div
              className="md:ml-14 lg:mb-10 mb-0 md:pt-16 md:max-w-4xl md:w-full flex flex-col items-start">
              <h1 className="font-extrabold leading-10 tracking-tight sm:leading-none md:text-6xl sm:text-4xl text-2xl">
                Flutter in_app_purchase plugin <br />or <br /> LinkFive
              </h1>
              <p className="my-5 text-lg text-gray-600 leading-relaxed md:w-4/5 md:text-xl md:mx-0 ">
                Sometimes it is difficult to choose between different plugins. Here is a short overview of the
                advantages of the LinkFive Flutter plugin compared to the in_app_purchase plugin.
              </p>
            </div>
          </div>
        </div>


        <section className="pb-8 text-gray-600 mt-16">
          <div className="container max-w-6xl mx-auto m-8 pt-0">
            <HeaderH2
              headerText="Valid Purchase & Validation"
              widthClass="w-4/6"
            />
            <p className="lg:max-w-2xl w-full leading-relaxed mx-auto text-center p-6 text-lg ">
              When a user makes a purchase through an App Store, the app is notified at the time of purchase. But what
              happens if the user subsequently cancels the App Store purchase? Is the app notified somehow?
            </p>
            <div className="flex flex-wrap mt-6">
              <div className="sm:w-1/2 p-6">
                <h3 className="mt-4 mb-8 lg:text-4xl text-2xl font-medium leading-10 tracking-tight text-gray-800">
                  in_app_purchase Plugin
                </h3>

                <ul className="leading-relaxed">
                  <LiCross>No Receipt Validation</LiCross>
                  <LiCross>No Subscription Status Notifications</LiCross>
                </ul>
                <p className="max-w-2xl mt-4 leading-relaxed text-lg">
                  The plugin can only communicate programmatically with the App Stores when the app is open and there is
                  no server-side validation. If a user makes a purchase through your app and cancels it in the App Store,
                  the app will only notified when the app is open and you check validation manually.
                </p>
                <p className="max-w-2xl mt-4 leading-relaxed text-lg">
                  With Apple it is even more difficult than with Google because you only get the receipt of the Flutter
                  iOS SDK. The receipt must then be sent manually to Apple together with the subscription password to
                  find out whether and how long the subscription will last.
                </p>
              </div>

              <div className="sm:w-1/2 p-6">
                <h3 className="mt-4 mb-8 lg:text-4xl text-2xl font-medium leading-10 tracking-tight text-gray-800">
                  LinkFive Plugin
                </h3>
                <ul className="leading-relaxed">
                  <LiCheck>Daily Receipt Validation</LiCheck>
                  <LiCheck>Instant Subscription Status Notifications</LiCheck>
                </ul>
                <p className="max-w-2xl mt-4 leading-relaxed text-lg">
                  The LinkFive Flutter plugin communicates with the LinkFive server and knows at any moment that a
                  subscription has been purchased or cancelled by the user in the App Store.
                </p>
                <p className="max-w-2xl mt-4 leading-relaxed text-lg">
                  The app is immediately notified of a successful purchase. If the user cancels a subscription, we
                  notify the app immediately.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-8 text-gray-600  mt-16">
          <div className="container max-w-6xl mx-auto m-8 pt-0">
            <HeaderH2
              headerText="Cross Device Premium Plan"
              widthClass="w-4/6"
            />
            <p className="lg:max-w-2xl w-full leading-relaxed mx-auto text-center p-6 text-lg">If a user buys a
              subscription on iOS, and the app is offered on Android, iOS and maybe even the web, the user should have
              an active subscription regardless of whether it is Android, iOS or web. </p>
            <div className="flex flex-wrap mt-6">
              <div className="sm:w-1/2 p-6">
                <h3 className="mt-4 mb-8 lg:text-4xl text-2xl font-medium leading-10 tracking-tight text-gray-800">
                  in_app_purchase Plugin
                </h3>

                <ul>
                  <LiCross>Only purchase on current device</LiCross>
                </ul>
                <p className="max-w-2xl mt-4 leading-relaxed text-lg">
                  The plugin only serves as a bridge to the native purchase sdk. So when a user buys a subscription,
                  there is no synchronisation between the stores or between the devices.
                </p>
              </div>

              <div className="sm:w-1/2 p-6">
                <h3 className="mt-4 mb-8 lg:text-4xl text-2xl font-medium leading-10 tracking-tight text-gray-800">
                  LinkFive Plugin
                </h3>
                <ul>
                  <LiCheck>Shares purchase regardless of device type. iOS, Android & Web.</LiCheck>
                </ul>
                <p className="max-w-2xl mt-4 leading-relaxed text-lg">
                  With LinkFive, the purchase is linked to the user. If the user has an active purchase, the user gets
                  the purchase status no matter where they log in.
                </p>
                <p className="max-w-2xl mt-4 leading-relaxed text-lg">
                  LinkFive's new user management even allows a premium status to be manually assigned to a user.
                </p>
              </div>
            </div>
          </div>
        </section>


        <section className="pb-8 text-gray-600  mt-16">
          <div className="container max-w-6xl mx-auto m-8 pt-0">
            <HeaderH2
              headerText="Dynamic Offerings"
              widthClass="w-4/6"
            />
            <p className="lg:max-w-2xl w-full leading-relaxed mx-auto text-center p-6 text-lg">If an app wants to be
              successful, it must tailor its offer to the country. We have seen with our clients that certain plan
              lengths sell better in certain countries than in others.</p>
            <div className="flex flex-wrap mt-6">
              <div className="sm:w-1/2 p-6">
                <h3 className="mt-4 mb-8 lg:text-4xl text-2xl font-medium leading-10 tracking-tight text-gray-800">
                  in_app_purchase Plugin
                </h3>

                <ul>
                  <LiCross>Hardcoded Product Ids</LiCross>
                </ul>
                <p className="max-w-2xl mt-4 leading-relaxed text-lg">
                  With the Flutter plugin, all product IDs must be entered as a string in the code. Once the app is
                  released, you cannot change the product ids dynamically. Changes are only possible after a new app
                  release
                </p>
              </div>

              <div className="sm:w-1/2 p-6">
                <h3 className="mt-4 mb-8 lg:text-4xl text-2xl font-medium leading-10 tracking-tight text-gray-800">
                  LinkFive Plugin
                </h3>
                <ul>
                  <LiCheck>Dynamic product offer that can be tailored to a country.</LiCheck>
                </ul>
                <p className="max-w-2xl mt-4 leading-relaxed text-lg">
                  The offer can be changed dynamically in our web UI. This means that the offer can be changed
                  immediately for all app versions all over the world or even only in certain countries.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-8 text-gray-600">
          <div className="container max-w-6xl mx-auto m-8 pt-0">
            <p className="lg:max-w-2xl w-full leading-relaxed mx-auto text-center p-6 text-lg font-medium">And finally:
              LinkFive
              uses the native in_app_purchase flutter plugin as the basis for its plugin. So if you use LinkFive, you
              also get all the features of the official in_app_purchase plugin.</p>
          </div>
        </section>

        <section>
          <div className="bg-l6-background px-15">
            <div
              className="max-w-5xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
              <p
                className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl text-center pb-10 lg:pb-0">
                <span className="block ">Ready to get started? It's free!</span>
              </p>

              <a
                href="//app.linkfive.io/sign-up?from=flutter"
                className="action-button focus:shadow-outline focus:outline-none"
              >
                Sign Up
              </a>
            </div>
          </div>
        </section>

      </Layout>
    </>
  )
}